import React from 'react'
import ReactDOM from 'react-dom/client'

import './initUiLib'
import './index.css'
import { App } from './App'
import { configure } from 'mobx'
import { createAppContext } from './ctx/MyContext'
import { ConfSource } from './etc/ConfSource'
import { FrontendConf } from '@apis/my'
import { isDev } from './dev/isDev'

configure({
    enforceActions: 'never',
})


const bootstrap = async (conf: FrontendConf) => {
    console.log('App version', conf.version)
    const ctx = createAppContext(conf)

    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
        <React.StrictMode>
            <App ctx={ctx} />
        </React.StrictMode>,
    )
    // To turn off strict mode (renders stuff twice in dev!)
    // ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    //     <App />,
    // )

}

const confSrc = new ConfSource<FrontendConf>(window, 'my-app-conf')


if (!isDev()) {
    bootstrap(confSrc.getSync())
} else {
    const getDevConf = async (): Promise<FrontendConf> => {
        if (import.meta.env.VITE_BACKEND_APP_CONF === 'true') {
            return confSrc.getFromServer()
        } else {
            console.log('Getting sync conf')
            return confSrc.getSync()
        }
    }

    getDevConf().then(conf => {
        console.log('App config', conf)
        bootstrap(conf)
    })
}




