import {isDev} from "../dev/isDev";

export class ConfSource<T> {


    constructor(private window: Window, private elId: string) {
    }

    getSync = (): T => {
        if (isDev()) {
            // @ts-ignore
            return __DEV_APP_CONFIG__
        }

        const confEl = window.document.getElementById(this.elId)
        if (!confEl)
            throw new Error(`Could not get the conf object`)

        return JSON.parse(confEl.textContent!) as T
    }

    getFromServer = async (): Promise<T> => {
        return fetch(`/api/spa/conf`)
            .then(r => r.json() as T)
    }
}
